import { Injectable, NgModule } from "@angular/core";
import { Router } from "@angular/router";
import { AlertController, ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";


@Injectable()
export class CardManualEntry {

    constructor(
        private router: Router,
        private alertController: AlertController,
        private translate: TranslateService,
        private toastController: ToastController
    ) {
    }
    
    async request() {
        // await this.alertController.present();
        this.manualEntry();
        setTimeout(() => {
            const input = document.getElementById('cardInput');
            const confirmButton = document.getElementById('confirmButton');
    
            if(input){
                input.addEventListener('keydown', function (event) {
                    if (event.key === 'Enter') {
                        event.preventDefault(); // Impede o comportamento padrão do Enter
                        confirmButton?.click(); // Simula o clique no botão de confirmação
                    }
                });
            }
        }, 300);
    }

    ionViewDidEnter() {
    }

    private async manualEntry() {
        console.log("aqui");
        let alert = await this.alertController.create({
            message: this.translate.instant('card.insert_card_number'),
            cssClass: 'custom-input-alert',
            inputs: [{
                type: 'text',
                name: 'card_number',
                label: this.translate.instant('card.label'),
                attributes: {
                    class: 'alert-input-focus',
                    id: 'cardInput'
                }
            }],
            buttons: [{
                text: this.translate.instant('cancel'),
                role: 'cancel',
            },
            {
                role: 'confirm',
                text: this.translate.instant('ok'),
                id: "confirmButton",
                handler: (values) => {
                    this.handleCardNumber(values.card_number);
                }
            }]
        })

        await alert.present();

        let input: any = document.querySelector('.alert-input-focus');

        if (input) input.focus();
    }

    public handleCardNumber(card_number: any) {
        console.log("aqui 2");
        if (!card_number) {
            return this.errorToast(
                this.translate.instant('card.missing_card_number')
            )
        }

        console.log("aqui 3", card_number);

        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/card-details'], { state: { card_number: card_number } });
        });
        // return this.router.navigateByUrl('/card-details', { state: { card_number: card_number } });
    }

    private async errorToast(message: any, duration: number = 6000) {

        let toast = await this.toastController.create({
            message,
            duration,
            color: 'danger',
            buttons: [this.translate.instant('ok')]
        })

        toast.present();
    }
}

@NgModule({
    providers: [
        {
            provide: CardManualEntry,
        }
    ]
})
export class CardManualEntryModule { }
