import { Injectable } from '@angular/core';
import { EventsService } from './events.service';
import { Router } from '@angular/router';
import { InnocardService } from './innocard.service';
import { UserPermissionsService } from './user-permissions.service';
import { Platform } from '@ionic/angular';
import { CardManualEntry } from '../helpers/card-manual-entry';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private role:any = '';

  private menu:any = [];
  private colors:any;

  constructor(
    public eventsService: EventsService,
    public router: Router,
    public innocardService: InnocardService,
    public permissionsService: UserPermissionsService,
    public platform:Platform,
    public manualEntry:CardManualEntry
  ) {

   this.init();
  }

  init() {
     /**
     * On logout, clear menu
     */
     this.eventsService.subscribe('user:sessionexpired', async () => {
      this.menu = [];
    });

    /**
     * On login, generate menu
     */
    this.eventsService.subscribe( 'user:signin', (e:any) => {
      this.role = e['RoleType'];
    });
  }

  private getNextColor() {
    const color:any = this.colors.shift();
    this.colors.push(color);
    
    return color;
  }

  private getColors()
  {
    return [
      'primary', 
      'secondary', 
      'tertiary', 
      'indigo',
      'success', 
      'warning', 
      'danger', // 'medium', 'dark', 
      'steel-blue', 
      'medium',
      'orchid',
      //'goldenrod',
      'turquoise',
      'maroon',
      'olive',
      //'teal',
      'chocolate',
    ];
  }

  private generateMenu(options:any)
  {
    this.colors = this.getColors();
    this.menu = [];

    if (options.home) {
      this.menu.push({
        title: 'home', url: '/', icon: 'home', color: this.getNextColor()
      });
    }
    
    if (this.permissionsService.isCustomer()) {
      [
        { title: 'cards', url: '/cards', icon: 'card', color: this.getNextColor() },
        { title: 'coupons', url: '/card-coupons', icon: 'pricetag', color: this.getNextColor() },
        { title: 'transactions.transactions', url: '/transactions', icon: 'cash', color: this.getNextColor() },
        { title: 'account_edit', url: '/account-edit', icon: 'person', color: this.getNextColor() }
      ].forEach(e => this.menu.push(e));
      console.log("Entrando aqui", this.getNextColor());
      
    } else if (this.permissionsService.isCompany() || this.permissionsService.isCompanyCompain()) {
      if (this.platform.is('mobile') || this.platform.is('tablet')) {
        this.menu.push({ title: 'qrcode.scan', url: '/qrcode', icon: 'qr-code', color: this.getNextColor() });
      }
    
      // Menu comum para TE, ME, TEC, MEC
      [
        { title: 'account_edit', url: '/edit-admin', icon: 'person', color: this.getNextColor() },
        { title: 'card.insert_card_number', icon: 'keypad', color: this.getNextColor(), handler: () => this.manualEntry.request() },
        { title: 'customers', url: '/customers', icon: 'people', color: this.getNextColor() },
        { title: 'transactions.transactions', url: '/transactions', icon: 'cash', color: this.getNextColor() },
        { title: 'transactionreports', url: '/transaction-report', icon: 'list', color: this.getNextColor() }
      ].forEach(e => this.menu.push(e));
    
      // Adicionar "Campanhas" e "Cupons" apenas para TEC e MEC
      if (this.permissionsService.isCompanyCompain()) {
        [
          { title: 'campaigns', url: '/campaigns', icon: 'megaphone', color: this.getNextColor() },
          { title: 'coupons', url: '/coupons', icon: 'pricetag', color: this.getNextColor() }
        ].forEach(e => this.menu.push(e));
      }
    }else if (this.permissionsService.isSuperAdmin()) {
      if (this.platform.is('mobile') || this.platform.is('tablet')) {
        this.menu.push({ title: 'qrcode.scan', url: '/qrcode', icon: 'qr-code', color: this.getNextColor() });
      }
      [
        { title: 'account_edit', url: '/edit-admin', icon: 'person', color: this.getNextColor() },
        { title: 'card.insert_card_number', icon: 'keypad', color: this.getNextColor(), handler: () => this.manualEntry.request() },
        { title: 'tennants', url: '/tennants', icon: 'business', color: this.getNextColor() },
        { title: 'merchants', url: '/merchants', icon: 'storefront', color: this.getNextColor() },
        { title: 'contracts', url: '/contracts', icon: 'document-text', color: this.getNextColor() },
        { title: 'distributions', url: '/distributions', icon: 'people', color: this.getNextColor() },
        { title: 'messages', url: '/messages', icon: 'chatbox-ellipses', color: this.getNextColor() },
        { title: 'terminals', url: '/terminals', icon: 'people', color: this.getNextColor() },
        { title: 'cards', url: '/cards', icon: 'card', color: this.getNextColor() },
        { title: 'card_status_schedules', url: '/card-status-schedules', icon: 'time', color: this.getNextColor() },
        { title: 'generatecards', url: '/generate-cards', icon: 'add', color: this.getNextColor() },
        { title: 'massload', url: '/mass-load', icon: 'grid', color: this.getNextColor() },
        { title: 'customers', url: '/customers', icon: 'people', color: this.getNextColor() },
        { title: 'transactions.transactions', url: '/transactions', icon: 'cash', color: this.getNextColor() },
        { title: 'campaigns', url: '/campaigns', icon: 'megaphone', color: this.getNextColor() },
        { title: 'bonus', url: '/bonus', icon: 'pricetag', color: this.getNextColor() },
        { title: 'coupons', url: '/coupons', icon: 'pricetags', color: this.getNextColor() },
        { title: 'clearing', url: '/clearing', icon: 'reader', color: this.getNextColor() },
        { title: 'transactionreports', url: '/transaction-report', icon: 'list', color: this.getNextColor() },
        { title: 'massTransactions', url: '/mass-transaction', icon: 'wallet', color: this.getNextColor() },
        { title: 'transactions_export', url: '/transactions-export', icon: 'document-text', color: this.getNextColor() },
        { title: 'users', url: '/users', icon: 'people', color: this.getNextColor() },
      ].forEach(e => this.menu.push(e));
    }
    

    if ( options.help ) {
      this.menu.push({
        title: 'help', icon: 'chatbox-ellipses', color: this.getNextColor(), handler: () => {
          window.open(
            'https://innocardloyalty.atlassian.net/wiki/spaces/DSP/overview', '_system'
          );
        }
      })
    }

    if (options.about) {
      this.menu.push({
        title: 'about', icon: 'information', color: this.getNextColor(), url: '/about'
      });
    }

    if (options.logout) {
      this.menu.push({
        title: 'signout', icon: 'exit', color: this.getNextColor(), handler: () => {
          this.logout();
        }
      });
    }
    
    /**
     * Returns promise to be able to generate menus asynchronously in the future
     */
    return new Promise((resolve) => {
      resolve(this.menu);
    })
  }

  get( role:any, options:any = {})
  {
    const o = {
      logout: true, 
      home: true, 
      about: true, 
      help: true, 
      contact: true,
      ...options
    }
    this.role = role && role.toUpperCase();
    return this.generateMenu(o)
  }

  private logout()
  {
    this.innocardService.logout();
    return this.router.navigate(['/login']);
  }

}
